import React from 'react';
import { Link } from 'react-router-dom';

// Page Configurations
export const REPORT_MESSAGE = <span>To report an adverse event or product complaint, call <Link to="tel:+18666963873">(866) 696-3873</Link></span>
export const RESOURCE_NOTICE = <span>The Neurelis Medical Affairs Resource Center provides scientific, disease state, and research information at your fingertips. You can search by topic or resource type to find the precise information you need. We add new content regularly, so you can be sure that you always have access to the most up-to-date research, right when you need it.
If you need help finding information, use the <Link to="/contact">Contact Us</Link> link to get in touch with a Medical Affairs professional.</span>
export const GRANTS_NOTICE = <p>Given the volume of requests received, we are limited in the number of grants awarded per year. All grant requests must be submitted through the online portal for consideration and will be reviewed on a rolling basis. <b>Please allow a minimum of 60 days between application submission and the date of your event.</b> <br/><br/> The Grants Committee review process takes approximately 6 weeks  from the date of submission. Every submission will receive communications regarding final decisions. If you have any questions concerning the grant application process, please contact <Link to="mailto:grants@neurelis.com"><b>grants@neurelis.com</b></Link></p>
export const PAGE_DETAILS =  {
	"/": {
		name: "Home",
		link: "/",
		pageTitle: "Neurelis Medical Affairs Resource Center",
		pageDescription: 'Explore Neurelis Medical Affairs Resource Page for quick access to scientific, disease state, and grant information in one place. Stay informed effortlessly.',
		topNav: false
	},
	"/resources": {
		name: "Resources",
		link: "/resources",
		pageTitle: "Neurelis Resources: Empowering Neurological Innovation",
		pageDescription: "Access valuable resources and educational materials on neurological treatments. Explore our comprehensive collection to empower your knowledge.",
		topNav: true,
		items: [
			{ name: 'Manuscripts', pageTitle: "Neurelis Manuscripts | Published Research Studies in Neurology", pageDescription: "Access Neurelis' published research studies in neurology. Gain insights into advancements and clinical data on neurological disorders.", icon: 'manuscripts', internal: true, link: '/manuscripts'},
			{ name: 'Posters / Presentations', pageTitle: "Neurelis Posters & Presentations | Advancements in Neurology", pageDescription: "Explore Neurelis' Posters & Presentations. Discover insights and advancements in neurology through concise visuals and impactful presentations.", icon:'posters', internal: true, link: '/posters'},
			{ name: 'Infographics', pageTitle: "Neurelis Infographics | Interactive Medical Insights", pageDescription: "Explore Neurelis' Visual Educational Infographics. Interactive resources offering insights and knowledge on medical advancements.", icon:'infographics', internal: true, link: '/infographics'},
			{ name: 'Slide Decks', pageTitle: "Neurelis Slide Decks | Nasal Anatomy/Physiology Insights", pageDescription: "Explore Neurelis' Intranasal Drug Development Slide Decks. Gain insights into nasal anatomy/physiology for optimized drug delivery.", icon:'slide', internal: true, link: '/slide'},
			{ name: 'Safety Information', pageTitle: "Neurelis Safety | Comprehensive Guidelines", pageDescription: "Explore Neurelis' Medical Affairs Safety page for comprehensive safety information and guidelines. Ensuring patient well-being through rigorous safety measures.", icon:'safety', internal: true, link: '/safety'},
			{ name: 'CME Activities', pageTitle: "Neurelis CME Activities | Seizure Information", pageDescription: "Explore Neurelis' CME Activities for comprehensive seizure information. Enhance your knowledge in neurology through engaging educational opportunities.", icon:'cme', internal: true, link: '/cme'},
			{ name: 'MSL Map', pageTitle: "Neurelis MSL Map | Connecting Neurology Experts", pageDescription: "Explore Neurelis' MSL Map page to discover our team's reach. Connecting healthcare professionals with neurology expertise across regions.", icon:'msl', internal: true, link: '/msl'},
			{ name: 'Podcasts', pageTitle: "Neurelis Podcast | Engaging Neurology Discussions", pageDescription: "Explore Neurelis' Medical Affairs Podcast for insightful discussions on neurology topics. Stay informed with engaging audio content from experts.", icon:'podcasts', internal: true, link: '/podcasts'},
			{ name: 'Patient Microsite', icon:'microsite', internal: false, link: 'https://www.neurelisclinicaltrials.com/'},
			{ name: 'Toolbox', icon:'toolbox', internal: false, link: 'https://www.neurelismedicalaffairs.com/pdfs/NER20VTC_1001_AcuteSeizureActionPlan_0317.pdf'}
		]
	},
	"/grants": {
		name: "Grants",
		link: "/grants",
		pageTitle: "Neurelis Medical Affairs: Advancing Neuro Grants",
		pageDescription: "Discover funding opportunities through Neurelis grants. Empowering research and innovation in neurology. Apply today for support and collaboration.",
		topNav: true,
		items: [
			{ name: 'Medical Education Grants', pageTitle: "Neurelis Grants | Advancing Education & Expertise", pageDescription: "Unlock Neurelis' Medical Affairs Grants for Neurology Education. Empowering healthcare professionals with funding to expand expertise in neurological disorders.", link: '/medical-education', icon: 'pi-home', internal: true},
			{ name: 'Healthcare-Related Contributions', pageTitle: "Neurelis Healthcare Contributions: Empowering Tax-Exempt Organizations", pageDescription: "Neurelis Healthcare Contributions: Supporting tax-exempt organizations in advancing independent scientific, educational, and advocacy initiatives for improved patient care", link: '/health-related', icon: 'pi-home', internal: true},
			{ name: 'Research Agreements', pageTitle: "Neurelis Research Agreements: Ethical Research in Epilepsy & CNS Disorders", pageDescription: "Neurelis Research Agreements: Supporting ethical independent research in epilepsy and central nervous system disorders by qualified investigators.", link: '/investigator-studies', icon: 'pi-home', internal: true},
			{ name: 'Frequently Asked Questions', pageTitle: "Frequently Asked Questions", pageDescription: "", link: '/faq', icon: 'pi-home', internal: true},
			{ name: 'Track Medical Education Grant Application', link: 'https://www.cybergrants.com/neurelis/MedicalEducation', internal: false},
			{ name: 'Track Healthcare-Related Contribution Application', link: 'https://www.cybergrants.com/neurelis/HealthRelatedContributions', internal: false},
			{ name: 'Track Investigator Initiated Study Application', link: 'https://www.cybergrants.com/neurelis/IISConcept', internal: false}
		]
	},
	"/events": {
		name: "Events",
		link: "/events",
		pageTitle: "Neurelis Events | Neurology Conferences & Educational Symposia",
		pageDescription: "Stay updated with Neurelis' Medical Events: Connect with experts, explore cutting-edge research, and advance your knowledge in neurological disorders and treatments.",
		topNav: true
	},
	"/pipeline": {
		name: "Pipeline",
		link: "/pipeline",
		pageTitle: "Neurelis Pipeline | Advancing Neurology Treatments & Therapies",
		pageDescription: "Discover Neurelis' Medical Affairs Pipeline: Innovations in neurology treatments, cutting-edge research, clinical trials, and advancements for improved patient outcomes.",
		topNav: true
	},
	"/about": {
		name: "About Us",
		link: "/about",
		pageTitle: "About Neurelis: Advancing Neurological Treatments",
		pageDescription: "Discover Neurelis Medical Affairs: Uniting expertise & innovation, we're dedicated to advancing neurological treatments for a brighter future. Learn more!",
		topNav: false,
		items: [
			{ name: 'View Investor Information', link: 'https://www.neurelis.com/our-partners', internal: false}
		]
	},
	"/contact": {
		name: "Contact",
		link: "/contact",
		pageTitle: "Neurelis Contact | Connect with Our Neurology Experts",
		pageDescription: "Connect with Neurelis' Medical Affairs: Contact our knowledgeable experts for inquiries, collaborations, and insights on neurology treatments and therapies.",
		topNav: true
	},
	"/mirf": {
		name: "Medical Information Request Form",
		link: "/mirf",
		pageTitle: "Neurelis Medical Affairs: Innovating Neuro Treatments",
		pageDescription: "Submit your medical information request effortlessly. Our dedicated team will provide comprehensive assistance and answers tailored to your needs.",
		topNav: false
		
	},
	"/follow-up": {
		name: "Follow Up Form",
		link: "/follow-up",
		pageTitle: "Neurelis Medical Affairs: Advancing Neuro Treatments",
		pageDescription: "Stay connected and receive personalized follow-up support. Fill out our form to ensure ongoing care and assistance from Neurelis Medical Affairs.",
		topNav: false
		
	},
	"/search": {
		name: "Search",
		link: "/search",
		pageTitle: "Neurelis Medical Affairs: Neuro Search Hub",
		pageDescription: "Efficiently search through Neurelis Medical Affairs resources. Find the information you need for advanced neurological treatments. Explore now!",
		topNav: false
		
	},
	"/faq": {
		name: "FAQ",
		link: "/faq",
		pageTitle: "Neurelis Medical Affairs FAQ | Answers & Expertise",
		pageDescription: "Find answers to commonly asked questions about Neurelis' medical affairs. Learn more about our expertise and commitment to patient care.",
		topNav: false

	}
}
export const PAGE_DETAILS_CONTACT = {
	"/contactSideMenu": {
		name: "Call",
		items: [
			{ name: 'Call', icon: 'phone', description: 'If you are in the United States and would like additional information regarding Neurelis products, please contact us at (866) 696-3873.', link: 'tel:8666963873'},
			{ name: 'Find An MSL', icon: 'msl', description: 'Have questions about Neurelis products?', link: '/resources/msl'}
		]
	}
}
export const PAGE_DETAILS_PIPELINE = {
	"/pipelineSideMenu": {
		name: "Call",
		items: [
			{ name: 'View Pipeline', icon: 'phone', description: 'Click the link to view our pipeline:', link: 'http:/google.com', internal: false},
			{ name: 'Contact Us', icon: 'phone', description: 'Contact us for more information.', link: '/contact'},
			{ name: 'More information', icon: 'phone', description: 'For more information on clinical trial status, reach out to your local MSL.', link: '/resources/msl'}
			
		]
	}
}